export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const ADD_ERROR = 'ADD_ERROR'
export const REMOVE_ERROR = 'REMOVE_ERROR'

export const SET_LOADING = 'SET_LOADING'
export const SET_SUCCESS = 'SET_SUCCESS'
export const SET_DELETE = 'SET_DELETE'

// export const SET_LOADING = "SET_LOADING";
export const ERROR_ITEM = 'ERROR_ITEM'
export const ERROR_ACCOUNT = 'ERROR_ACCOUNT'
export const ERROR_EXPENSE = 'ERROR_EXPENSE'
export const ERROR_STAFFS = 'ERROR_STAFFS'
export const ERROR_PURCHASES = 'ERROR_PURCHASES'
export const ERROR_STOCKS = 'ERROR_STOCKS'
export const ERROR_TRANSFERS = 'ERROR_TRANSFERS'
export const ERROR_BADITEMS = 'ERROR_BADITEMS'
export const ERROR_OWNER = 'ERROR_OWNER'
export const ERROR_SHOP = 'ERROR_SHOP'
export const ERROR_VOUCHERS = 'ERROR_VOUCHERS'
export const ERROR_MERCHANT = 'ERROR_MERCHANT'

export const SHOW_MERCHANTS = 'SHOW_MERCHANTS'
export const SHOW_MERCHANT = 'SHOW_MERCHANT'
export const CREATE_MERCHANTS = 'CREATE_MERCHANTS'
export const UPDATE_MERCHANTS = 'UPDATE_MERCHANTS'
export const FILTER_MERCHANTS = 'FILTER_MERCHANTS'
export const IS_SUCCESS_MERCHANT = 'IS_SUCCESS_MERCHANT'

export const SHOW_MEMBERS = 'SHOW_MEMBERS'
export const SHOW_MEMBER = 'SHOW_MEMBER'
export const CREATE_MEMBERS = 'CREATE_MEMBERS'
export const UPDATE_MEMBERS = 'UPDATE_MEMBERS'
export const FILTER_MEMBERS = 'FILTER_MEMBERS'

export const CREATE_EXPENSES = 'CREATE_EXPENSES'
export const SHOW_EXPENSES = 'SHOW_EXPENSES'
export const SHOW_EXPENSE = 'SHOW_EXPENSE'
export const UPDATE_EXPENSES = 'UPDATE_EXPENSES'
export const FILTER_EXPENSES = 'FILTER_EXPENSES'

export const SHOW_ITEMS = 'SHOW_ITEMS'
export const SHOW_ITEM = 'SHOW_ITEM'
export const CREATE_ITEMS = 'CREATE_ITEMS'
export const UPDATE_ITEMS = 'UPDATE_ITEMS'
export const FILTER_ITEMS = 'FILTER_ITEMS'

export const SHOW_SUPPLIERS = 'SHOW_SUPPLIERS'
export const CREATE_SUPPLIERS = 'CREATE_SUPPLIERS'

export const SHOW_ITEMS_TRANSFER = 'SHOW_ITEMS_TRANSFER'
export const SHOW_TRANSFER = 'SHOW_TRANSFER'
export const CREATE_ITEMS_TRANSFER = 'CREATE_ITEMS_TRANSFER'
export const FILTER_ITEMS_TRANSFER = 'FILTER_ITEMS_TRANSFER'
export const UPDATE_ITEMS_TRANSFER = 'UPDATE_ITEMS_TRANSFER'

export const SHOW_ITEMS_IMPORTS = 'SHOW_ITEMS_IMPORTS'
export const CREATE_ITEMS_IMPORTS = 'CREATE_ITEMS_IMPORTS'

export const SHOW_SHOPS = 'SHOW_SHOPS'
export const SHOW_SHOP = 'SHOW_SHOP'
export const CREATE_SHOPS = 'CREATE_SHOPS'
export const FILTER_SHOPS = 'FILTER_SHOPS'
export const UPDATE_SHOPS = 'UPDATE_SHOPS'
export const IS_SUCCESS_SHOP = 'IS_SUCCESS_SHOP'

export const CLEAR_ALERT = 'CLEAR_ALERT'
// export const CLEAR_ALERT_ITEMS = 'CLEAR_ALERT_ITEMS';

export const SHOW_ACCOUNTS = 'SHOW_ACCOUNTS'
export const SHOW_ACCOUNT = 'SHOW_ACCOUNT'
export const CREATE_ACCOUNTS = 'CREATE_ACCOUNTS'
export const UPDATE_ACCOUNTS = 'UPDATE_ACCOUNTS'
export const FILTER_ACCOUNTS = 'FILTER_ACCOUNTS'
export const CHANGE_ACCOUNTS = 'CHANGE_ACCOUNTS'
export const IS_SUCCESS_ACCOUNT = 'IS_SUCCESS_ACCOUNT'
export const CLEAR_ALERT_ACCOUNT = 'CLEAR_ALERT_ACCOUNT'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'

export const SHOW_EXPENSENAMES = 'SHOW_EXPENSENAMES'
export const SHOW_EXPENSENAME = 'SHOW_EXPENSENAME'
export const CREATE_EXPENSENAMES = 'CREATE_EXPENSENAMES'
export const UPDATE_EXPENSENAMES = 'UPDATE_EXPENSENAMES'
export const FILTER_EXPENSENAMES = 'FILTER_EXPENSENAMES'

export const SHOW_SERVICES = 'SHOW_SERVICES'
export const SHOW_SERVICE = 'SHOW_SERVICE'
export const CREATE_SERVICES = 'CREATE_SERVICES'
export const UPDATE_SERVICES = 'UPDATE_SERVICES'
export const FILTER_SERVICES = 'FILTER_SERVICES'

export const SHOW_STAFFS = 'SHOW_STAFFS'
export const SHOW_STAFF = 'SHOW_STAFF'
export const CREATE_STAFFS = 'CREATE_STAFFS'
export const UPDATE_STAFFS = 'UPDATE_STAFFS'
export const FILTER_STAFFS = 'FILTER_STAFFS'

export const SHOW_BONUS = 'SHOW_BONUS'
export const SHOW_BONU = 'SHOW_BONU'
export const CREATE_BONUS = 'CREATE_BONUS'
export const UPDATE_BONUS = 'UPDATE_BONUS'
export const FILTER_BONUS = 'FILTER_BONUS'

export const SHOW_PURCHASES = 'SHOW_PURCHASES'
export const SHOW_PURCHASE_REPORT = 'SHOW_PURCHASE_REPORT'
export const SHOW_PURCHASE = 'SHOW_PURCHASE'
export const CREATE_PURCHASES = 'CREATE_PURCHASES'
export const UPDATE_PURCHASES = 'UPDATE_PURCHASES'
export const FILTER_PURCHASES = 'FILTER_PURCHASES'

export const SHOW_BADITEMS = 'SHOW_BADITEMS'
export const SHOW_BADITEM = 'SHOW_BADITEM'
export const PAGINATE_BESTITEMS = 'PAGINATE_BESTITEMS'
export const CREATE_BADITEMS = 'CREATE_BADITEMS'
export const UPDATE_BADITEMS = 'UPDATE_BADITEMS'
export const FILTER_BADITEMS = 'FILTER_BADITEMS'
export const IS_SUCCESS_ERROR_BADITEMS = 'IS_SUCCESS_ERROR_BADITEMS'

export const SHOW_OWNERS = 'SHOW_OWNERS'
export const CREATE_OWNERS = 'CREATE_OWNERS'
export const UPDATE_OWNERS = 'UPDATE_OWNERS'
export const FILTER_OWNERS = 'FILTER_OWNERS'
export const SHOW_OWNER = 'SHOW_OWNER'

export const SHOW_VOUCHERS = 'SHOW_VOUCHERS'
export const SHOW_VOUCHER = 'SHOW_VOUCHER'
export const CREATE_VOUCHERS = 'CREATE_VOUCHERS'
export const UPDATE_VOUCHERS = 'UPDATE_VOUCHERS'
export const FILTER_VOUCHERS = 'FILTER_VOUCHERS'
export const PAGINATE_VOUCHERS = 'PAGINATE_VOUCHERS'

export const CREATE_PURCHASE_CREDITS = 'CREATE_PURCHASE_CREDITS'
export const FILTER_PURCHASE_CREDITS = 'FILTER_PURCHASE_CREDITS'
export const ERROR_PURCHASE_CREDITS = 'ERROR_PURCHASE_CREDITS'
export const IS_SUCCESS_PURCHASE_CREDITS = 'IS_SUCCESS_PURCHASE_CREDITS'

export const SHOW_STOCKS = 'SHOW_STOCKS'
export const UPDATE_STOCKS = 'UPDATE_STOCKS'
export const DELETE_STOCKS = 'DELETE_STOCKS'

export const SET_REPORTS = 'SET_REPORTS'
export const SET_DAILY_REPORTS = 'SET_DAILY_REPORTS'

export const SHOW_DAILY_STAFFS = 'SHOW_DAILY_STAFFS'
export const SHOW_DAILY_STAFF = 'SHOW_DAILY_STAFF'
export const CREATE_DAILY_STAFFS = 'CREATE_DAILY_STAFFS'
export const UPDATE_DAILY_STAFFS = 'UPDATE_DAILY_STAFFS'
export const FILTER_DAILY_STAFFS = 'FILTER_DAILY_STAFFS'
export const ERROR_DAILY_STAFFS = 'ERROR_DAILY_STAFFS'

export const SHOW_PRICE_TRACK_ITEMS = 'SHOW_PRICE_TRACK_ITEMS'

export const SHOW_LUCKYS = 'SHOW_LUCKYS'
export const SHOW_LUCKY = 'SHOW_LUCKY'
export const CREATE_LUCKY = 'CREATE_LUCKY'
export const UPDATE_LUCKY = 'UPDATE_LUCKY'
export const FILTER_LUCKY = 'FILTER_LUCKY'

export const SHOW_UNITS = 'SHOW_UNITS'
export const SHOW_UNIT = 'SHOW_UNIT'
export const DELETE_UNIT = 'DELETE_UNIT'

export const SHOW_CHANGE_SHOPS = 'SHOW_CHANGE_SHOPS'
export const SHOW_CHANGE_STOCKS = 'SHOW_CHANGE_STOCKS'

export const CREATE_RULES = 'CREATE_RULES'
export const SHOW_RULES = 'SHOW_RULES'
export const SHOW_RULE = 'SHOW_RULE'
export const DELETE_RULE = 'DELETE_RULE'
export const UPDATE_RULES = 'UPDATE_RULES'
